export class Websql {
  db: any;

  constructor(db: any) {
    this.db = db;
  }

  async executeSql(queryStatement: string, params?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.db.transaction((tx) => {
        tx.executeSql(queryStatement, params,
          (tx, result) => {
            resolve(result);
          },
          (error) => reject(error));
      });
    });
  }

  async sqlBatch(statements: any[]): Promise<any> {
    return new Promise((r, error) => {
      const batch = [];

      for (const statement of statements) {
        batch.push(new Promise<void>((resolve, reject) => {
          if (typeof statement === 'string') {
            this.executeSql(statement).then(() => resolve()).catch(reject);
          } else {
            this.executeSql(statement[0], statement[1]).then(() => resolve()).catch(reject);
          }
        }));
        Promise.all(batch).then(() => {
          r(true);
        }).catch((e => {
          error(e);
        }));
      }
    });
  }
}
