import {Injectable, inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, CanMatchFn, Route, Router, RouterStateSnapshot, UrlSegment} from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';
import {UserService} from '../services/user.service';
import {NetworkService} from '../services/network.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(
    private oauthService: OAuthService,
    private networkService: NetworkService,
    private userService: UserService,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.networkService.hasInternet) {
      return true;
    }

    const hasAccessToken = this.oauthService.hasValidAccessToken();
    if (hasAccessToken && this.userService.currentUser) {
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }

  canMatch(route: Route, segments: UrlSegment[]): boolean {
    if (!this.userService.currentUser) {
      return false;
    }
    if (!this.userService.currentUser.hasRole(route.data.roles)) {
      this.router.navigate(['/tabs']);
      return false;
    } else {
      return true;
    }
  }
}

export const authActivateGuard: CanActivateFn =  (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(AuthGuard).canActivate(next, state);
}

export const authCanMatchGuard: CanMatchFn = (route: Route, segments: UrlSegment[]) => {
  return inject(AuthGuard).canMatch(route, segments);
};
