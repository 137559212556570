import {Injectable, NgZone} from '@angular/core';
import {Network} from '@ionic-native/network/ngx';
import {Platform} from '@ionic/angular';
import {fromEvent} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  public hasInternet: boolean;

  constructor(
    private platform: Platform,
    private network: Network,
    private zone: NgZone
  ) {
  }

  checkInternet(): void {

    if (this.platform.is('hybrid')) {
      this.setNetworkStatus(this.network.type !== this.network.Connection.NONE);
      this.network.onChange().subscribe(() => {
        const connected = this.network.type !== this.network.Connection.NONE;
        this.setNetworkStatus(connected);
      });
    } else {
      this.setNetworkStatus(navigator.onLine);
      fromEvent(window, 'online').subscribe(() => {
        this.setNetworkStatus(true);
      });
      fromEvent(window, 'offline').subscribe(() => {
        this.setNetworkStatus(false);
      });
    }
  }

  private setNetworkStatus(status: boolean): void {
    this.zone.run(() => {
      this.hasInternet = status;
    });
  }
}
