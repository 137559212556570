import {Injectable, NgZone} from '@angular/core';
import {Platform} from '@ionic/angular';
import {Storage} from '@ionic/storage-angular';
import {AuthConfig, OAuthService, OAuthStorage} from 'angular-oauth2-oidc';
import {environment} from '../../environments/environment';
import {InAppBrowser, InAppBrowserOptions} from '@ionic-native/in-app-browser/ngx';
import {Router} from '@angular/router';
import {UserService} from './user.service';
import {BehaviorSubject} from 'rxjs';
import {NetworkService} from './network.service';
import {App, AppState} from '@capacitor/app';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  loading = true;
  hasAccessToken = new BehaviorSubject(false);

  authCodeFlowConfig: AuthConfig = {
    issuer: `${environment.authConfig.host}/auth/realms/${environment.authConfig.realm}`,
    redirectUri: window.location.origin + '/login',
    clientId: environment.authConfig.clientId,
    responseType: 'code',
    scope: 'openid profile email offline_access',
    showDebugInformation: environment.authConfig.showDebugInformation,
    requireHttps: environment.authConfig.requireHttps,
    openUri: uri => {
      if (this.platform.is('hybrid')) {
        this.mobileLogin(uri);
      } else {
        location.href = uri;
      }
    }
  };

  constructor(
    private platform: Platform,
    private storage: Storage,
    private oauthService: OAuthService,
    private oauthStorage: OAuthStorage,
    private iab: InAppBrowser,
    private zone: NgZone,
    private router: Router,
    private userService: UserService,
    private networkService: NetworkService,
    private appService: AppService
  ) {
  }

  async setUpOathService(): Promise<void> {
    if (! this.oauthService.tokenEndpoint) {
      console.log('setup oauth');
      if (this.platform.is('hybrid')) {
        this.authCodeFlowConfig.redirectUri = 'evdmapp://login';
      }
      this.oauthService.configure(this.authCodeFlowConfig);
      try {
        await this.oauthService.loadDiscoveryDocument();
        this.oauthService.setupAutomaticSilentRefresh();
        this.hasAccessToken.next(this.oauthService.hasValidAccessToken());
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    }
  }

  async refreshTokenOnActive(): Promise<void> {
    App.addListener('appStateChange', async (state: AppState) => {
      if (state.isActive && this.networkService.hasInternet) {
        await this.setUpOathService();
      }
    });
  }

  async forgottenPassword() {
    const url = environment.resetPasswordUrl;
    console.log('Forgot password.');

    const options: InAppBrowserOptions = {
      zoom: 'no',
      location: 'no',
      clearsessioncache: 'yes',
      clearcache: 'yes',
      footer: 'yes',
      presentationstyle: 'pagesheet',
      closebuttoncaption: "Sluiten",
      hidden: 'yes'
    };
    const browser = this.iab.create(url, '_blank', options);
    browser.on('loadstop').subscribe(() => {
      browser.executeScript({code: 'document.querySelector("meta[name=viewport]").setAttribute(\'content\',"viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no");'});
      browser.show();
    });
    const listener = browser.on('loadstart').subscribe((event: any) => {
      const callback = encodeURI(event.url);
      console.log(callback);
      if (callback.indexOf(this.oauthService.redirectUri) > -1) {
        this.loading = true;
        browser.close();
        this.zone.run(() => {
          const params = new URL(callback).searchParams;
          this.router.navigate(['/login'], {
            queryParams: {state: params.get('state'), session_state: params.get('session_state'), code: params.get('code')}
          }).then(() => {
            listener.unsubscribe();
          });
        });
      }
    });
  }


  async login() {
    console.log('try login');

    try {
      console.log(Date.now());
      console.log(this.oauthService.redirectUri);
      await this.oauthService.tryLogin();
      const refreshToken = await this.storage.get('refresh_token');
      if (!this.oauthService.hasValidAccessToken() && refreshToken !== null) {
        console.log('try by refresh token');
        this.oauthStorage.setItem('refresh_token', refreshToken);
        await this.oauthService.refreshToken();
      }
      if (this.oauthService.hasValidAccessToken()) {
        this.hasAccessToken.next(true);
        await this.storage.set('refresh_token', this.oauthService.getRefreshToken());
        if (this.userService.currentUser === undefined) {
          await this.userService.setCurrentUser();
        }
       // Login succeded check app version
        this.appService.checkAppVersion();
        this.zone.run(() => {
          this.router.navigate(['/']);
        });
      } else {
        this.loading = false;
      }
    } catch (e) {
      this.loading = false;
      console.error(e.toString());
    }
  }

  mobileLogin(url) {
    const options: InAppBrowserOptions = {
      zoom: 'no',
      location: 'no',
      clearsessioncache: 'yes',
      clearcache: 'yes',
      presentationstyle: 'pagesheet',
      hidden: 'yes'
    };

    const browser = this.iab.create(url, '_blank', options);
    browser.on('loadstop').subscribe(event => {
      browser.executeScript({code: 'document.querySelector("meta[name=viewport]").setAttribute(\'content\',"viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no");'});
      browser.show();
    });
    const listener = browser.on('loadstart').subscribe((event: any) => {
      const callback = encodeURI(event.url);
      if (callback.indexOf(this.oauthService.redirectUri) > -1) {
        this.loading = true;
        browser.close();
        this.zone.run(() => {
          const params = new URL(callback).searchParams;
          this.router.navigate(['/login'], {
            queryParams: {state: params.get('state'), session_state: params.get('session_state'), code: params.get('code')}
          }).then(() => {
            listener.unsubscribe();
          });
        });

      }
    });
  }
}
