import {switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import {Observable, from, of} from 'rxjs';
import {OAuthService} from 'angular-oauth2-oidc';
import {environment} from '../../environments/environment';
import {NetworkService} from '../services/network.service';


@Injectable()

export class AccessTokenInterceptor implements HttpInterceptor {
  constructor(
    private oauthService: OAuthService,
    private networkService: NetworkService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith(environment.apiUrl) ) {
      if (!this.oauthService.tokenEndpoint || !this.networkService.hasInternet) {
        return of();
      } else if (! this.oauthService.hasValidAccessToken()) {
        return from(this.oauthService.refreshToken()).pipe(
          switchMap(token => {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${token.access_token}`
              }
            });
            return next.handle(request);
          }));
      } else {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.oauthService.getAccessToken()}`
          }
        });
        return next.handle(request);
      }
    } else {
      return next.handle(request);
    }
  }
}
