import { AfterContentChecked, Component } from '@angular/core';
import { UserService } from './services/user.service';
import { Transporter, TransporterService } from './services/transporter.service';
import { LoginService } from './services/login.service';
import { tap } from 'rxjs/operators';
import { DocumentError, DocumentService } from './services/document.service';
import { AppService } from './services/app.service';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent implements AfterContentChecked{

  transporters: Transporter[];
  documentErrors: DocumentError[];

  constructor(
    public userService: UserService,
    private loginService: LoginService,
    public transportService: TransporterService,
    public documentService: DocumentService,
    public appService: AppService,
    private readonly androidPermissions: AndroidPermissions
  ) {
    this.loginService.hasAccessToken.pipe(
      tap(hasValidAccessToken => {
        if (hasValidAccessToken) {
          this.userService.currentUserChanged.pipe(
            tap(bool => {
              if (bool && !this.transporters && this.userService.currentUser && this.userService.currentUser.isRovecom()) {
                this.transportService.getTransporters().subscribe(res => this.transporters = res);
              }
            })
          ).subscribe();
        }
      })
    ).subscribe();

    this.checkPermissions();
  }

  async selectTransporter(): Promise<void> {
    this.userService.currentUser.combination = null;
    await this.userService.saveCurrentUser();
    window.location.reload();
  }

  checkPermissions() {
    if (this.appService.isAndroid()) {
      const permission = this.androidPermissions.PERMISSION.POST_NOTIFICATIONS;
      this.androidPermissions.checkPermission(permission).then(
        result => {
          if (!result.hasPermission) {
            // No permision time to ask!
            this.androidPermissions.requestPermission(permission).then(
               result => console.log('Ok',result),
               err => console.log('Error', err.message)
            );
          }
        },
        err => this.androidPermissions.requestPermission(permission)
      );
    }
  }

  compareWith(o1: Transporter, o2: Transporter) {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  }

  ngAfterContentChecked(): void {
    this.documentErrors = this.documentService.documentErrors;
  }


}
