import { Injectable } from '@angular/core';
import { from, lastValueFrom, Observable, of } from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {NetworkService} from './network.service';
import {environment} from '../../environments/environment';
import { DatabaseService } from './database.service';

@Injectable({
  providedIn: 'root'
})

export class ProcessprotocolService {

  public apiUrl = environment.apiUrl + '/process_protocols';
  public latestGet: number;
  private processingProtocols: ProcessProtocol[] = [];
  public defaultProcessingProtocol: ProcessProtocol;

  constructor(
    private http: HttpClient,
    private networkService: NetworkService,
    private databaseService: DatabaseService
  ) {
    this.getLatestGet().then(resp => this.latestGet = resp);
  }


  async getLatestGet(): Promise <number> {
    let latestGet = 0;
    this.processingProtocols = [];
    if (this.databaseService.hasDatabase) {
      const sql = 'SELECT latestGet, processprotocol FROM processprotocols;';
      await this.databaseService.database.executeSql(sql, []).then(
        resp => {


          for (let i=0; i<resp.rows.length; i++) {
            latestGet = JSON.parse(resp.rows.item(1).latestGet) as number;
            const processProtocol = JSON.parse(resp.rows.item(i).processprotocol) as ProcessProtocol
            this.processingProtocols.push(processProtocol);

            if (processProtocol.code == 98100 ) {
              this.defaultProcessingProtocol = processProtocol;
            }
          }
        }
      );
    }
    return (latestGet);
  }

  async getLocalProcessingProtocols(): Promise <ProcessProtocol[]> {
    if (this.databaseService.hasDatabase && this.processingProtocols.length == 0) {
      const sql = 'SELECT processprotocol FROM processprotocols;';
      await this.databaseService.database.executeSql(sql, [])
        .then(
          resp => {
            for (let i=0; i<resp.rows.length; i++) {
              const processProtocol = JSON.parse(resp.rows.item(i).processprotocol) as ProcessProtocol
              this.processingProtocols.push(processProtocol);

              if (processProtocol.code == 98100 ) {
                this.defaultProcessingProtocol = processProtocol;
              }
            }
          })
        .catch(error => console.error(error));
    }
    if (this.processingProtocols.length == 0) {
      // No process protocols so get them from online.
      this.processingProtocols = await lastValueFrom(this.getOnlineProcessingProtocols());
    }
    return (this.processingProtocols);
  }

  getProcessingProtocols(): Observable<ProcessProtocol[]> {
    return from(this.getLocalProcessingProtocols());
  }


  getOnlineProcessingProtocols(): Observable<ProcessProtocol[]> {
    if (this.networkService.hasInternet) {
      return this.http.get<any>(this.apiUrl).pipe(
        tap(resp => {
          this.defaultProcessingProtocol = resp.process_protocols.find(processProtocol => processProtocol.code.toString() === '98100');
        }),
        map(resp => {
          const processProtocols = resp.process_protocols as ProcessProtocol[];
          const current = new Date();
          this.updateProcessProtocolsInDatabase(processProtocols, current.getTime());
          return processProtocols;
        })
      );
    } else {
      return of([]);
    }
  }

  updateProcessProtocolsInDatabase(processProtocols: ProcessProtocol[], latestGet: number) {
    if (this.databaseService.hasDatabase) {
      this.latestGet = latestGet;
      let processProtocolQueries = [];
      processProtocolQueries.push(['DELETE FROM processprotocols']);

      processProtocolQueries = processProtocolQueries.concat(
          processProtocols.map(processProtocol => ['INSERT INTO processprotocols VALUES(?,?,?)', [processProtocol.code, JSON.stringify(processProtocol), latestGet]]));

      this.databaseService.database.sqlBatch(processProtocolQueries).then(() => console.log('processProtocols added in database'));
    }
  }

}

export interface ProcessProtocol {
  code: number;
  name: string;
  use_estimated_weights: boolean;
  require_gr_vehicle: boolean;
  allow_gr_vehicle: boolean;
  require_one_of_comment_keys: string[];
  forbidden_comment_keys: string[];
  allowed_fertilizer_codes: string[];
  forbidden_fertilizer_codes: string[];
  client_is_bmc: boolean;
  commerce_key_bmc?: string;
  client_is_smg: boolean;
  commerce_key_smg?: string;
  number_of_transports: number;
  report_manual_unloading: boolean;
  sampling: boolean;
  use_ecert_sampling_data: boolean;
  use_ecert_gr_data: boolean;
  barcode: boolean;
}
