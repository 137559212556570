import { Injectable } from '@angular/core';
import { from, lastValueFrom, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { NetworkService } from './network.service';
import { DatabaseService } from './database.service';


@Injectable({
  providedIn: 'root'
})
export class FertilizerService {

  public fertilizerUrl = environment.apiUrl + '/fertilizer_codes';
  public latestGet: number;

  constructor(
    private http: HttpClient,
    private networkService: NetworkService,
    private databaseService: DatabaseService
  ) {
    this.getLatestGet().then(resp => this.latestGet = resp);
  }


  async getLatestGet(): Promise <number> {
    let latestGet = 0;
    if (this.databaseService.hasDatabase) {
      const sql = 'SELECT latestGet FROM fertilizers LIMIT 1;';
      await this.databaseService.database.executeSql(sql, []).then(
        resp => {
          if (resp.rows.length > 0) {
            latestGet = JSON.parse(resp.rows.item(0).latestGet) as number;
          }
        }
      );
    }
    return (latestGet);
  }

  async getLocalFertilizers(): Promise <FertilizerCode[]> {
    let fertilizers: FertilizerCode[] = [];
    if (this.databaseService.hasDatabase) {
      const sql = 'SELECT * FROM fertilizers';
      await this.databaseService.database.executeSql(sql, []).then(
        resp => {
          for (let i=0; i<resp.rows.length; i++) {
            fertilizers.push(JSON.parse(resp.rows.item(i).fertilizer) as FertilizerCode);
          }

        }
      );
    }
    if (fertilizers.length == 0) {
      // No process protocols so get them from online.
      fertilizers = await lastValueFrom(this.getFertilizersOnline());
    }
    return (fertilizers);
  }

  getFertilizers(): Observable<FertilizerCode[]> {
    return from(this.getLocalFertilizers());
  }

  getFertilizersOnline(): Observable<FertilizerCode[]> {
    if (this.networkService.hasInternet) {
      return this.http.get<any>(this.fertilizerUrl).pipe(
        map(resp => {
          const fertilizers = resp.fertilizer_codes as FertilizerCode[];
          const current = new Date();
          this.updateFertilizersInDatabase(fertilizers, current.getTime());
          return fertilizers;
        })
      );
    } else
    {
      return of ([]);
    }
  }

  updateFertilizersInDatabase(fertilizers: FertilizerCode[], latestGet: number) {
    if (this.databaseService.hasDatabase) {
      this.latestGet = latestGet;
      let FertilizerQueries = [];
      FertilizerQueries.push(['DELETE FROM fertilizers']);

      FertilizerQueries = FertilizerQueries.concat(
        fertilizers.map(fertilizer => ['INSERT INTO fertilizers VALUES(?,?,?)', [fertilizer.code, JSON.stringify(fertilizer), latestGet]]));

      this.databaseService.database.sqlBatch(FertilizerQueries).then(() => console.log('Fertilizers added in database'));
    }
  }
}

export interface FertilizerCode {
  code: string;
  name: string;
  description: string;
}
