import {APP_INITIALIZER, NgModule, ErrorHandler} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {OAuthModule, OAuthService} from 'angular-oauth2-oidc';
import {HttpClientModule} from '@angular/common/http';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {IonicStorageModule} from '@ionic/storage-angular';
import {environment} from '../environments/environment';
import {Network} from '@ionic-native/network/ngx';
import {ServiceWorkerModule} from '@angular/service-worker';
import {AppInitService} from './app-init.service';
import {httpInterceptorProviders} from './interceptors';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {SQLite} from '@awesome-cordova-plugins/sqlite/ngx';
import {IconPack} from '@fortawesome/fontawesome-svg-core';
import { AirbrakeErrorHandler } from './airbrake-error-handler';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Market } from '@ionic-native/market/ngx';
import { WebIntent } from '@awesome-cordova-plugins/web-intent/ngx';
import { AppAvailability } from '@awesome-cordova-plugins/app-availability/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { PdfViewerModule } from 'ng2-pdf-viewer';

export function initApp(
  appInitService: AppInitService
) {
  return async (): Promise<any> => {
    return await appInitService.init();
  };
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        OAuthModule.forRoot(),
        FontAwesomeModule,
        PdfViewerModule,
        IonicStorageModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    providers: [
        { provide: SQLite },
        { provide: Network },
        { provide: InAppBrowser },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: OAuthService },
        { provide: Market },
        { provide: ErrorHandler, useClass: AirbrakeErrorHandler },
        AndroidPermissions,
        WebIntent,
        AppAvailability,
        AppInitService,
        AppVersion,
        { provide: APP_INITIALIZER, useFactory: initApp, deps: [AppInitService], multi: true },
        httpInterceptorProviders
    ],
    bootstrap: [AppComponent],
    exports: [PdfViewerModule]
})
export class AppModule {
  constructor(
    private  library: FaIconLibrary
  ) {
    this.library.addIconPacks(far as IconPack, fas as IconPack, fab as IconPack);
  }
}
