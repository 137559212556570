import {Injectable, NgZone} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {NetworkService} from './network.service';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {Storage} from '@ionic/storage-angular';
import {Router} from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';
import {Transporter} from './transporter.service';
import {Combination} from './combination.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  currentUserChanged = new BehaviorSubject<boolean>(false);
  currentUser: User;
  apiUrl = environment.apiUrl + '/users';
  truckdriverUrl = environment.apiUrl + '/truck_drivers';

  constructor(
    private http: HttpClient,
    private storage: Storage,
    private networkService: NetworkService,
    private router: Router,
    private zone: NgZone,
    private oauthService: OAuthService
  ) {

  }


  async initCurrentUser(): Promise<void> {
    try {
      const storedUser = JSON.parse(await this.storage.get('currentUser')) as IUser || undefined;
      if (storedUser) {
        const user = new User(storedUser);
        // If storedUser is not a rovecomUser refresh the usersettings, to get most current transporter settings.
        // Do NOT do this as rovecomUser. Then you can no longer switch transporter.
        if (user && !user.isRovecom() && this.networkService.hasInternet) {
          this.setCurrentUser();
        } else {
          this.currentUser = user;
          this.currentUserChanged.next(true);
        }
      }
    } catch (error) {
      console.error('Failed to load current user!');
    }
  }

  getTruckdrivers(): Observable<User[]> {
    if (this.networkService.hasInternet) {
      let params = new HttpParams();
      return this.http.get<any>(this.truckdriverUrl, {params}).pipe(
        map(resp => resp.truck_drivers)
      );
    }
  }

  currentUserIsAdmin(): boolean {
    if (this.currentUser) {
       return this.currentUser.hasRole(['rovecom','admin','administration']);
    }
    return false;
  }

  async setCurrentUser(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.networkService.hasInternet) {
        this.http.get <any>(this.apiUrl + '/current_user_detail').pipe(
          map(resp => {
            const user = resp as IUser;
            this.currentUser = new User(user);
            this.saveCurrentUser();
            resolve();
          })).subscribe();
      } else {
        reject('No internet');
      }
    }).then();
  }

  async saveCurrentUser(): Promise<void> {
    await this.storage.set('currentUser', JSON.stringify(this.currentUser));
    this.currentUserChanged.next(true);
  }

  async logout() {

    await this.oauthService.revokeTokenAndLogout().then(async () => {
      await this.storage.remove('currentUser');
      await this.storage.remove('refresh_token');
      this.currentUser = null;
    });

    this.zone.run(() => {
      this.router.navigate(['/login']);
      window.location.reload();
    });
  }
}

export interface IUser {
  id: string;
  name: string;
  email: string;
  mobile: string;
  roles: Role[];
  transporter?: Transporter;
  combination?: Combination;
  isRovecom: () => boolean;
  hasRole: (name: string[]) => boolean;
}

export class User implements IUser {
  id: string;
  name: string;
  email: string;
  mobile: string;
  roles: Role[];
  default_vehicle_id: number;
  default_vehicle_lock: boolean;
  transporter?: Transporter;
  combination?: Combination;

  constructor(user: IUser) {
    for (const key of Object.keys(user)) {
      this[key] = user[key];
    }
  }

  isRovecom(): boolean {
    return this.hasRole(['rovecom']);
  }

  hasRole(roles: string[]): boolean {
    return this.roles.some(r => roles.includes(r.name));
  }
}


export interface Role {
  name: string;
}
