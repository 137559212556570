import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { AppService } from './app.service';
import { CommentService, RvdmComment } from './comment.service';
import { FertilizerCode, FertilizerService } from './fertilizer.service';
import { NetworkService } from './network.service';
import { ProcessProtocol, ProcessprotocolService } from './processprotocol.service';

@Injectable({
  providedIn: 'root'
})

export class MasterDataSyncService {

constructor(
    private readonly networkService: NetworkService,
    private readonly http: HttpClient,
    private readonly commentService: CommentService,
    private readonly processProtocolService: ProcessprotocolService,
    private readonly fertilizerService: FertilizerService,
    private readonly platform: Platform,
    private readonly appService: AppService
  ) { }

  private dataurl = environment.apiUrl + '/data';



  getOldestLatestGet(): number {
    let latestGet = this.commentService.latestGet;
    if (latestGet > this.processProtocolService.latestGet)
    {
      latestGet = this.processProtocolService.latestGet;
    }
    if (latestGet > this.fertilizerService.latestGet)
    {
      latestGet = this.fertilizerService.latestGet;
    }
    return latestGet;
  }


  checkForNewMasterData(): Observable<masterData> {
    if (this.networkService.hasInternet) {
      const latestGet = this.getOldestLatestGet();
      let params = new HttpParams();
      if (latestGet) {
        const latestDateTime = new Date();
        latestDateTime.setTime(latestGet);
        params = params.set('modified_since', latestDateTime.toISOString());
      }
      params = params.set('app_version', this.appService.appVersionText());

      const platforms = this.platform.platforms();
      if (platforms.length) {
        params = params.set('platforms', this.appService.appPlatforms());
      }
      console.log('checkForNewMasterData since: ' + latestGet);
      return this.http.get<any>(this.dataurl, {params});
    } else {
      return of ();
    }
  }
}

export interface masterData {
  comments: RvdmComment[],
  fertilizer_codes: FertilizerCode[],
  process_protocols: ProcessProtocol[]
}
