import {Injectable} from '@angular/core';
import {LoginService} from './services/login.service';
import {NetworkService} from './services/network.service';
import {Network} from '@ionic-native/network/ngx';
import {Platform} from '@ionic/angular';
import {DatabaseService} from './services/database.service';
import {UserService} from './services/user.service';
import {environment} from '../environments/environment';

@Injectable()
export class AppInitService {

  constructor(
    private loginService: LoginService,
    private networkService: NetworkService,
    private network: Network,
    private platform: Platform,
    private databaseService: DatabaseService,
    private userService: UserService
  ) {
  }

  async init() {
    console.log('Init app');
    console.log('Version ' + environment.version);
    await this.platform.ready();

    console.log('check network');
    this.networkService.checkInternet();

    console.log('Init oauth');
    await this.loginService.setUpOathService();
    await this.loginService.refreshTokenOnActive();

    console.log('Init database');
    await this.databaseService.init();
    if (this.databaseService.database !== undefined) {
      console.log('create tables');
      await this.databaseService.createTables();
    }

    console.log('Init user');
    await this.userService.initCurrentUser();
  }
}
