import { Injectable } from '@angular/core';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { AlertController, Platform } from '@ionic/angular';
import { Market } from '@ionic-native/market/ngx';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NetworkService } from './network.service';
import { AppAvailability } from '@awesome-cordova-plugins/app-availability/ngx';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService  {

  currentAppVersion: string = '';
  buildVersion: string = '';
  appMessage: string[] ;
  versionUrl = environment.apiUrl + '/version';
  notificationsUrl = environment.apiUrl + '/notifications';

  constructor(
    private readonly appVersion: AppVersion,
    private readonly platform: Platform,
    private readonly alertController: AlertController,
    private readonly market: Market,
    private readonly http: HttpClient,
    private readonly networkService: NetworkService,
    private readonly appAvailability: AppAvailability,
  ) {
    if (this.appMobile()) {
      this.platform.ready().then(() => {
        this.appVersion.getVersionNumber().then(res => {
          this.currentAppVersion = res;
        }).catch(error => {
          console.error(error);
        });
      });
      this.appVersion.getVersionCode().then(res => {
        this.buildVersion = res.toString();
      }).catch(error => {
        console.error(error);
      });
    }
    this.appMessage = [];
  }

  async checkForNotifications() {
    if (!this.networkService.hasInternet) {
      return;
    }

    await lastValueFrom(this.http.get<AppNotification[]>(this.notificationsUrl)).then(resp => {
      this.appMessage = [];
      if (resp) {
        resp.forEach(notification => {
          this.appMessage.push(notification.description);
        });
      }
    });
  }


  checkAppVersion() {
    if (!this.appMobile() || !this.networkService.hasInternet) {
      return;
    }

    this.http.get<AppVersionNumber>(this.versionUrl).subscribe(resp => {
      if (resp) {
        const splittedCurrentAppVersion = this.currentAppVersion.split('.');
        const splittedStoreAppVersion = resp.version_number.split('.');

        if (splittedStoreAppVersion[0] > splittedCurrentAppVersion[0] || splittedStoreAppVersion[1] > splittedCurrentAppVersion[1] ) {
          // Major update!
          this.versionAlert("Belangrijke update!", "Er is een belangrijke eVDM®-app update beschikbaar.\nGa via Download naar de store om te updaten. ",  false);
        } else if (splittedStoreAppVersion[2] > splittedCurrentAppVersion[2])  {
          this.versionAlert("Update", "Er is een eVDM®-app update beschikbaar. \nGa via Download naar de store om te updaten.", true);
        }
      }
    });
  }

  openStore() {

    if (this.platform.is('android')) {
      this.market.open('rovecom.loonwerk.evdm');
    } else {
      this.market.open('id1644368206');
    }
  }

  isAndroid(): boolean {
    return this.platform.is('android');
  }

  async appExists(packageName: string): Promise<boolean> {
    return await this.appAvailability.check(packageName);
  }

  async versionAlert(title: string, message: string, allowClose: boolean = false) {
    const buttons: any = [];

    buttons.push({
      text: 'Download',
      handler: () => {
        this.openStore();
      }
    });

    if (allowClose) {
      buttons.push({
        text: 'Sluiten',
        role: 'cancel'
      });
    }

    const alert = await this.alertController.create({
      header: title,
      message: message,
      cssClass: 'alert-content', // Apply the custom class
      buttons: buttons,
      backdropDismiss: allowClose
    });
    await alert.present();
  }

  appMobile(): boolean {
    return this.platform.is('hybrid');
  }

  appVersionText(): string {
    if (this.appMobile()) {
      return `${this.currentAppVersion} (${this.buildVersion})`
    }
    else {
      return 'browser';
    }
  }

  appPlatforms(): string {
    const platforms = this.platform.platforms();
    if (platforms.length) {
      return platforms.join(',');
    }
    return '';
  }

}

export interface AppNotification {
  id: number;
  app_active: boolean;
  description: string;
  site_active: boolean;
}

export interface AppVersionNumber {
  version_number: string;
}

