import {Injectable} from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import {empty, Observable} from 'rxjs';
import {UserService} from '../services/user.service';
import {OAuthService} from 'angular-oauth2-oidc';
import {NetworkService} from '../services/network.service';
import {environment} from '../../environments/environment';


@Injectable()

export class CurrentTransporterInterceptor implements HttpInterceptor {
  constructor(
    private userService: UserService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith(environment.apiUrl)
      && this.userService.currentUser
      && this.userService.currentUser.transporter
      && this.userService.currentUser.isRovecom()) {
      request = request.clone({
        setHeaders: {
          transporterId: this.userService.currentUser.transporter.id.toString()
        }
      });
      return next.handle(request);
    } else {
      return next.handle(request);
    }
  }
}
