import {tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserService} from '../services/user.service';
import {AirbrakeErrorHandler} from '../airbrake-error-handler';


@Injectable()

export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private userService: UserService,
    private airBrake: AirbrakeErrorHandler
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        // Operation failed; error is an HttpErrorResponse
        error => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              this.airBrake.handleError('AuthInterceptor: Unautherized: Logging out.');
              this.userService.logout();
            }
          }
        }
      )
    );
  }
}
