import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { NetworkService } from './network.service';


@Injectable({
  providedIn: 'root'
})
export class TransporterService {

  public apiUrl = environment.apiUrl + '/transporters';

  transporters: Transporter[] = [];

  constructor(
    private http: HttpClient,
    private networkService: NetworkService
  ) {
  }

  getTransporters(): Observable<Transporter[]> {
    if (this.networkService.hasInternet) {
      return this.http.get<any>(this.apiUrl).pipe(
        map(resp => {
          this.transporters = resp.transporters as Transporter[]
          return this.transporters;
        })
      );
    }
  }

  transporterCollectsRoutes(id: number): boolean {
    const transporter = this.transporters.find(t => t.id === id);
    return transporter ? transporter.collect_route : false;

  }
}

export interface Transporter {
  id: number;
  collect_route: boolean;
  name: string;
  lab_key: string;
  hours_before_start_notification: number;
  data2track_active: boolean;
}

