import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { Websql } from '../helpers/websql';
import { SQLite, SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';

declare const window: any;

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  public database: SQLiteObject | Websql;
  public hasDatabase = false;

  dbVersion = '1.8';

  constructor(
    private sqlite: SQLite,
    private storage: Storage,
    private platform: Platform
  ) {
  }

  async init(): Promise<void> {
    try {
      await this.storage.create();
      const currentDbVersion = await this.storage.get('db_version');
      console.log(`current db version ${currentDbVersion}`)
      if (this.platform.is('hybrid')) {
        await this.platform.ready();
        this.database = await this.sqlite.create({
          name: 'evdm.db',
          location: 'default'
        });
        this.hasDatabase = true;
      } else if ("openDatabase" in window)  {
        const db = window.openDatabase('evdm_db', '', 'DEV', 5 * 1024 * 1024);
        if (currentDbVersion === null) {
          db.changeVersion('', this.dbVersion);
        } else {
          db.changeVersion(currentDbVersion, this.dbVersion);
        }
        this.database = new Websql(db);
        this.hasDatabase = true;
      }
      else {
        this.hasDatabase = false;
      }

      if (this.hasDatabase === true) {
        if (currentDbVersion !== undefined && currentDbVersion !== this.dbVersion) {
          await this.dropTables();
        }
        await this.storage.set('db_version', this.dbVersion);
      }
    } catch (e) {
      console.error(e);
    }
  }

  async createTables(): Promise<void> {
    return await this.database.sqlBatch([
      'CREATE TABLE IF NOT EXISTS documents (id, kind, truck_id, trailer_id, document, updatedOffline)',
      'CREATE TABLE IF NOT EXISTS processprotocols (id, processprotocol, latestGet)',
      'CREATE TABLE IF NOT EXISTS fertilizers (id, fertilizer, latestGet)',
      'CREATE TABLE IF NOT EXISTS comments (id, comment, latestGet)',
      'CREATE TABLE IF NOT EXISTS offline_weights (document, send_notification, empty_weight_image, full_weight_image)',
      'CREATE TABLE IF NOT EXISTS offline_route (id, route, route_at)',
      'CREATE TABLE IF NOT EXISTS started_documents (id, started_at)',
      'CREATE TABLE IF NOT EXISTS document_route (document_id, route_position)',
      'CREATE TABLE IF NOT EXISTS offline_document_changes (id, document, partialDocument)'
    ]);
  }

  async dropTables(): Promise<void> {
    // Never drop started_documents and document_route.
    // These are the driven routes from the driver.
    // Also do not drop offline tables
    return await this.database.sqlBatch([
      'DROP TABLE documents',
      'DROP TABLE processprotocols',
      'DROP TABLE fertilizers',
      'DROP TABLE comments'
    ]);
  }
}
