import { ErrorHandler, Injectable } from '@angular/core';
import { Notifier } from '@airbrake/browser';
import { environment } from '../environments/environment';
import { AppService } from './services/app.service';
import { UserService } from './services/user.service';

@Injectable({
  providedIn: 'root'
})

export class AirbrakeErrorHandler extends ErrorHandler {
  airbrake: Notifier;

  constructor(
    private appService: AppService,
    private userService: UserService,
  ) {
    super();
    this.airbrake = new Notifier({
      projectId: 1,
      projectKey: 'c8954dce1814c63e8a750e4d6df68395',
      environment: environment.environment,
      host: 'https://exceptions.rovecom.nl',
      remoteConfig: false,
    });
  }


  handleError(error: any): void {
    let appParams: any;
    if (this.userService.currentUser) {
      appParams = {
        version: this.appService.appVersionText(),
        platforms: this.appService.appPlatforms(),
        user: this.userService.currentUser.name,
        transporter: this.userService.currentUser.transporter ? this.userService.currentUser.transporter.name : 'No transporter',
      }
    } else {
      appParams = {
        version: this.appService.appVersionText(),
        platforms: this.appService.appPlatforms(),
      }
    }
    this.airbrake.notify({error, params: appParams})
    super.handleError(error);
  }
}
