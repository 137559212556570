<ion-app>
  <ion-menu *ngIf="this.userService.currentUser" type="overlay" side="start" menuId="first"
            contentId="main">
    <ion-header>
      <ion-toolbar color="medium">
        <ion-title (click)="this.userService.logout()">{{this.userService.currentUser.name}}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <div (click)="userService.logout()">
          <ion-item>
            <ion-icon name="log-out-outline" slot="start"></ion-icon>
            <ion-label>Uitloggen</ion-label>
          </ion-item>
        </div>
      </ion-list>

      <ion-card mode="md">
        <ion-card-header>
          <ion-card-subtitle>Gegevens</ion-card-subtitle>
        </ion-card-header>

        <ion-item>
          <ion-icon name="person-outline" slot="start"></ion-icon>
          <ion-label>{{userService.currentUser.name}}</ion-label>
        </ion-item>
        <ion-item>
          <ion-icon name="mail-outline" slot="start"></ion-icon>
          <ion-label>{{userService.currentUser.email}}</ion-label>
        </ion-item>
        <ion-item>
          <ion-icon name="call-outline" slot="start"></ion-icon>
          <ion-label>{{userService.currentUser.mobile}}</ion-label>
        </ion-item>
        <ion-item *ngIf="userService.currentUser && userService.currentUser.isRovecom() && this.transporters">
          <ion-label> <fa-icon [icon]="['far', 'building']" transform="right-5"></fa-icon></ion-label>
          <ion-select
            interface="action-sheet"
            [compareWith]="compareWith"
            label=""
            [(ngModel)]="this.userService.currentUser.transporter"
            (ionChange)="selectTransporter()"
            cancelText="Annuleer">
            <ion-select-option *ngFor="let transporter of this.transporters" [value]="transporter">{{transporter.name}}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item *ngIf="appService.appMobile()">
          <ion-icon name="phone-portrait-outline" slot="start"></ion-icon>
         <ion-label>App version: {{appService.appVersionText()}}</ion-label>
        </ion-item>
      </ion-card>
    </ion-content>
  </ion-menu>

  <ion-menu type="overlay" side="start" menuId="errors" contentId="main">
    <ion-header>
      <ion-toolbar color="medium">
        <ion-title>Meldingen</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content  color="warning">
      <ion-item  color="warning">
        <ul>
          <div *ngFor="let errorMessage of this.documentErrors">
            <li class="indent" *ngIf="errorMessage.isDetailRow">{{errorMessage.message}}</li>
            <li *ngIf="!errorMessage.isDetailRow">{{errorMessage.message}}</li>
          </div>
        </ul>
      </ion-item>
  </ion-content>

  </ion-menu>

  <ion-router-outlet id="main"></ion-router-outlet>

  <ion-content *ngIf="userService.currentUser && userService.currentUser.isRovecom() && !userService.currentUser.transporter && this.transporters" [fullscreen]="true">

    <ion-item >
      <ion-select
          interface="action-sheet"
          label="Selecteer een vervoerder"
          [compareWith]="compareWith"
          [(ngModel)]="this.userService.currentUser.transporter"
          (ionChange)="selectTransporter()">
        <ion-select-option *ngFor="let transporter of this.transporters" [value]="transporter">{{transporter.name}}</ion-select-option>
      </ion-select>
    </ion-item>
  </ion-content>
</ion-app>

