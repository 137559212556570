import { Injectable, inject } from "@angular/core";
import {ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot} from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';
import {UserService} from '../services/user.service';
import {NetworkService} from '../services/network.service';



@Injectable({
  providedIn: 'root'
})

export class LoginGuard {

  constructor(
    private oauthService: OAuthService,
    private networkService: NetworkService,
    private userService: UserService) {
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (this.networkService.hasInternet === false) {
      return true;
    }

    const hasAccessToken = this.oauthService.hasValidAccessToken();
    return !(hasAccessToken && this.userService.currentUser);

  }

}

export const loginActivateGuard: CanActivateFn =  (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(LoginGuard).canActivate(next, state);
}
